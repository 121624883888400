// media query
@mixin max-screen($break-point:767px) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}

@mixin min-screen($break-point:768px) {
  @media screen and (min-width: $break-point), print {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}


// SP 幅指定計算
@mixin width-sp($width) {
  width: #{$width / 750 * 100%};
}


// 左側のリンク矢印
@mixin arrow-left($size:4px, $color:$color_red) {
  position: relative;
  padding-left: #{$size + 11};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    vertical-align: middle;
    width: $size;
    height: $size;
    border: $size solid transparent;
    border-left: #{$size + 1} solid $color;
  }
}

// 左側・上向きのリンク矢印
@mixin arrow-left-top($size:4px, $color:$color_red) {
  position: relative;
  padding-left: #{$size + 11};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    vertical-align: middle;
    width: $size;
    height: $size;
    border: $size solid transparent;
    border-bottom: #{$size + 1} solid $color;
  }
}

