// =========================================
//   reset
// =========================================
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  -webkit-text-size-adjust: 100%;
}

body *,
body *::before,
body *::after {
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  display: block;
}

ul {
  list-style: none;
}

li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after, q::before, q::after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #444;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

img {
  vertical-align: top;
}


// =========================================
//   base
// =========================================
body {
  background-color: #fff;
  color: $color_black;
  font-family: $font-family-min;
  font-size: 14px;
  line-height: 1.78;
  position: relative;
}

a,
a:link,
a:visited {
  color: $color_blue;
  text-decoration: none;
}

a:hover {
  color: $link_hover;
  transition: color $hover_animate;

  & img {
    opacity: $hover_opacity;
    transition: opacity $hover_animate;
  }
}

a[href^="tel:"],
a[href^="tel:"]:link,
a[href^="tel:"]:hover,
a[href^="tel:"]:visited {
  cursor: text;
  color: $color_black;
}

.db {
  display: block !important;
}

.dib {
  display: inline-block !important;
}

.dtb {
  display: table !important;
  margin: auto;
}

.dtbc {
  display: table-cell !important;
  vertical-align: top;
}

.flt {
  &-l {
    float: left;
  }
  &-r {
    float: right;
  }
}

.indent {
  display: block;
  padding-left: 1em;
  text-indent: -1em;
}

.indent02 {
  display: inline-block;
  padding-left: 2.5em;
  text-indent: -2.5em;
}

.text_indent {
  text-indent: 1em;
}

.fBold {
  font-weight: bold;
  font-size: 120%;
}

.txt-red {
  color: #d20000;
}

.tc {
  text-align: center !important;
}

.tl {
  text-align: left !important;
}

.tr {
  text-align: right !important;
}

@for $i from 10 through 30 {
  .fs#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 0 through 20 {
  .mt#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }
  
  .mb#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
  
  .ml#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }
  
  .mr#{$i * 5} {
    margin-right: #{$i * 5}px !important;
  }
  
  .pt#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }
  
  .pb#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
  
  .pl#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }
  
  .pr#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }
}

ul.en {
  padding-left: 20px;
  li {
    display: list-item;
    list-style-type: disc;
  }
}

.inner {
  margin-left: auto;
  margin-right: auto;
  width: 1044px;
  padding: 0 10px;

  &-max {
    max-width: 1620px;
    min-width: 1044px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
  }
}

@include min-screen {
  .sp {
    display: none !important;
  }
}

#wrapper {
  padding-top: 155px;

  &.paddingT0 {
    padding-top: 0;
  }
}

// -----------------------------------------
//   clearfix
// -----------------------------------------
.clearfix:after {
  content: ",";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 0.1em; 
  line-height: 0; 
}

.clearfix { /* for ie6 */
  display: inline-block;
}

.clearfix { /* for Opera */
  display: block;
}


// -----------------------------------------
//   btn
// -----------------------------------------
.btn {
  font-family: $font-family-min;
  display: inline-block;
  padding: 5px 50px;
  transform: skewX(-25deg);
  
  &:hover {
    transition: background-color 0.3s ease;
  }
  
  span {
    display: block;
    transform: skewX(25deg);
    &[class^="icon-"] {
      padding-left: 20px;
      &::before {
        border: none;
        width: 15px;
        height: 15px;
      }
    }
    @include arrow-left(4px, #fff);
  }
  
  &-red {
    background-color: $color_red;
    color: #fff;
    &:link,
    &:visited {
      @extend .btn-red;
    }
    &:hover {
      background-color: #ee4d5a;
    }
  }
  
  &-black {
    background-color: #222;
    color: #fff;
    &:link,
    &:visited {
      @extend .btn-black;
    }
    &:hover {
      background-color: #626262;
    }
  }
  
  &-green {
    background-color: #006e3c;
    color: #fff;
    &:link,
    &:visited {
      @extend .btn-green;
    }
    &:hover {
      background-color: #499673;
    }
  }
  
  &-border {
    background-color: #fff;
    border: solid 1px #222;
    color: #222;
    &:link,
    &:visited {
      @extend .btn-border;
    }
    span:before {
      border-left: 6px solid #222;
    }
    &:hover {
      background-color: #eee;
    }
  }
  
  &.space-b {
    margin-bottom: 60px;
  }
  &.space-b02 {
    margin-bottom: 20px;
  }
}


// =========================================
//   SP
// =========================================
@include max-screen {
  html,
  body {
    overflow-x: hidden;
  }
  
  @for $i from 10 through 30 {
    .fs#{$i}-sp {
      font-size: #{$i}px !important;
    }
  }
  
  @for $i from 0 through 20 {
    .mt#{$i * 5}-sp {
      margin-top: #{$i * 5}px !important;
    }
    
    .mb#{$i * 5}-sp {
      margin-bottom: #{$i * 5}px !important;
    }
    
    .ml#{$i * 5}-sp {
      margin-left: #{$i * 5}px !important;
    }
    
    .mr#{$i * 5}-sp {
      margin-right: #{$i * 5}px !important;
    }
    
    .pt#{$i * 5}-sp {
      padding-top: #{$i * 5}px !important;
    }
    
    .pb#{$i * 5}-sp {
      padding-bottom: #{$i * 5}px !important;
    }
    
    .pl#{$i * 5}-sp {
      padding-left: #{$i * 5}px !important;
    }
    
    .pr#{$i * 5}-sp {
      padding-right: #{$i * 5}px !important;
    }
  }
  
  a:hover img {
    opacity: 1;
  }

  img {
    width: 100%;
    height: auto;
    &.img-default-sp {
      width: auto;
      max-width: 100%;
    }
  }

  .inner {
    margin-left: $sp_contents_padding;
    margin-right: $sp_contents_padding;
    padding: 0;
    width: auto;
  
    &-max {
      min-width: 0;
      margin-left: $sp_contents_padding;
      margin-right: $sp_contents_padding;
      padding: 0;
    }
    
    &-sp-none {
      margin: 0;
    }
  }
  
  .pc {
    display: none !important;
  }
  
  // -----------------------------------------
  //   btn
  // -----------------------------------------
  .btn {
    font-size: 12px;
    line-height: 1.4;
    padding: 10px 50px;
    &-green {
      background-color: #006e3c;
      color: #fff;
      &:hover {
        background-color: #4c9976;
      }
    }
    &.mini {
      padding: 5px 30px;
      font-size: 11px;
      span {
        padding-left: 12px;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          vertical-align: middle;
          width: 3px;
          height: 3px;
          border: 3px solid transparent;
          border-left: 4px solid #fff;
        }
      }
    }
    &.w-min {
      padding: 10px 40px;
    }
    &.w-min02 {
      padding: 10px 15px;
    }
    &.space-b {
      margin-bottom: 10%;
    }
    &.space-b02 {
      margin-bottom: 4%;
    }
  }

  #wrapper {
    padding-top: 65px;

    &.paddingT0 {
      padding-top: 0;
    }
  }
}
