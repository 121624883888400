@mixin alignCenter() {
  display: flex;
  align-items: center;
};

@include min-screen(){
  // header,gnav関連はPCとSPで構造が変わるため、それぞれスタイルを独立させる。ヘッダー以外はPCのスタイルをSPへ継承している。

  .headerFixed {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  // =========================================
  //   header
  // =========================================
  #header {
    background-color: #fff;
    font-family: $font-family-go;
    padding: 15px 0 25px;
    position: relative;
    min-width: 1044px;
    z-index: 9999;
  
    p {
      margin-bottom: 0;
    }
  
    .header-inner {
      display: flex;
      width: 100%;
    }

    .header-nav {
    margin-left: auto;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 805px;

    li {

      a,
      a:link,
      a:visited {
        color: $color_black;
      }

      a:hover {
        color: $link_hover;
      }
    }

    .recruit ,.homeGallery {
      a,
      a:link,
      a:visited {
        color: white;
      }
    }

    .recruit {
      width: 92px;

      a {
        position: relative;
        display: block;
        background-color: #E60012;
        border-radius: 5px;
        line-height: 30px;
        text-align: center;
        padding-left: 16px;

        &::before {
          position: absolute;
          width: 7px;
          height: 9px;
          top: 0;
          bottom: 0;
          left: 13px;
          margin: auto;
          background-color: white;
          clip-path: polygon(0 0, 0% 100%, 100% 50%);
          content: '';
        }
      }

      &:hover {
        opacity: 0.7;
        transition-duration: 0.3s;
      }
    }

    .homeGallery {

      a {
        position: relative;
        display: block;
        padding: 12px 0;
        padding-left: 33px;
        padding-right: 16px;
        background-color: #006E3D;
        border-radius: 5px;
        line-height: 18px;
        font-weight: bold;

        .link_icon {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 10px;
          margin: auto 0;
          display: block;
          width: 12px;
        }
      }

      &:hover {
        opacity: 0.7;
        transition-duration: 0.3s;
      }
    }

    .searchIcon {
      margin-top: -2px;
      vertical-align: middle;
    }
    }

    .gnav-serch {
    display: flex;
    align-items: center;

    dt {
      padding-right: 7px;
    }
    
    dd {
      display: flex;
      overflow: hidden;
    }

    input[type="text"] {
      border: 1px solid #b5b4b4;
      border-radius: 5px 0 0 5px;
      display: block;
      font-size: 14px;
      padding: 5px;
      width: 150px;
    }
    
    .btn-submit {
      background-color: #b5b4b4;
      border: 1px solid #b5b4b4;
      border-radius: 0 5px 5px 0;
      display: block;
      padding: 0;
      text-indent: -9999px;
      overflow: hidden;
      width: 30px;
      @include arrow-left(6px, #fff);
      
      &::before {
        top: 0;
        bottom: 0;
        left: 5px;
        right: 0;
        margin: auto; 
      }
    }
    }
  }

  .singleMenu {
    padding: 0 50px;

    a {
      &,
      &:link,
      &:visited {
        color: #333;
      }
      
      &:hover {
        color: $link_hover;
        transition: color $hover_animate;
      }
    }
  }

  .megamenu {
    padding: 0 50px;

    &:first-child {
      padding-left: 0;
    }
    a {
      &,
      &:link,
      &:visited {
        color: #333;
      }
      
      &:hover {
        color: $link_hover;
        transition: color $hover_animate;
      }
    }
  
    &-wrap {
      display: none;
      background-color: #fff;
      border-top: 1px solid #ebebeb;
      border-bottom: 1px solid #9c9c9c;
      padding: 10px 35px 30px;
      position: absolute;
      top: 64px;
      z-index: 10000;
  
      li{
        margin-top: 15px;
        
        a {
          display: inline-block;
          @include arrow-left(6px);
          padding-left: 21px;
        }
      }
  
      ul ul {
        margin-left: 20px;
      }
    }
  
    // house
    &-house {
      left: 10px;
      padding-top: 15px;
      width: 1016px;
  
      &-col-wrap {
        display: flex;
        
        dt {
          border-bottom: 1px solid #e0e0e0;
          padding-bottom: 5px;
          margin-bottom: 10px;
        }
        
        & .megamenu-house-lineup {
          display: flex;
          justify-content: space-between;
          margin: 0;
          
          li {
            margin-top: 7px;
            width: 30.48%;
          }
          
          a {
            display: block;
            padding-left: 0;
  
            &::before {
              content: none;
            }
            
            span {
              line-height: 1.4;
              display: inline-block;
              margin-top: 7px;
              @include arrow-left(5px);
              
              &::before {
                top: 5px;
                bottom: auto;
              }
            }
          }
        }
      }
    }
  
    &-left-wrap {
      border-right: 1px dotted #d3d3d3;
      margin-right: 35px;
      padding: 0 35px 15px 0;
      width: 548px;
    }
    
    &-right-wrap {
      width: 368px;
    }
  
    // application
    &-application {
      left: 388px;
      width: 597px;
      
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        &::after {
          content: '';
          width: 100px;
          display: block;
        }
        
        li {
          padding-right: 20px;
          &:nth-child(3n-2){width:137px;}
          &:nth-child(3n-1){width:210px;}
          &:nth-child(3n){width:180px;}
          //width: 167px;
          
          //&:nth-child(3n-1) {
          //  width: 210px;
          //}
          
          //&:nth-child(3n) {
          //  width: 100px;
          //}
        }
        &::after { // ダミー
          content: "";
          width: 180px;
        }
      }
    }
    // estate
    &-estate {
      left: 125px;
      width: 597px;
      
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        
        &::after {
          content: '';
          width: 100px;
          display: block;
        }
        
        li {
          padding-right: 20px;
          width: 175px;
        }
      }
    }

    &-sus {
      left: 450px;
      width: 550px;
      
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
                
        li {
          padding-right: 20px;
          width: 50%;
        }
      }
    }
  }

  .megamenu:hover {// アイテムにホバー時メガメニュー出現
    .megamenu-wrap {
      display: block;
    }
  }

  #gnav {
    background-color: #f9f9f9;
    position: relative;
    min-width: 1044px;
    z-index: 9999;
    
    p {
      margin-bottom: 0;
    }

    .gnav-inner {
      position: relative;
      z-index: 9999;
    }
  
    .gnav-inner > ul {
      display: flex;
      justify-content: space-between;
      position: relative;
      
      & > li {
        &:hover {
          & > a::after {
            background-color: $color_red;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 6px;
          }
        }
      }
  
      & > li > a,
      & > li > a:link,
      & > li > a:visited {
        color: #333;
        display: block;
        font-family: $font-family-go;
        font-weight: bold;
        padding: 20px 0;
        position: relative;
      }
    }
  }
  
  .spHomeGallery {
    display: none;
  }

  #megaMenuOverlay {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition-duration: 0.1s;
    z-index: 0;

    &.js-active {
      visibility: visible;
      opacity: 0.3;
      transition-duration: 0.3s;
      z-index: 10;
    }
  }
}


// =========================================
//   footer
// =========================================
#footer {
  font-family: $font-family-go;
  display: flex;
  padding: 20px 10px;
  
  p {
    margin-bottom: 0;
  }
  
  .footer-left {
    width: 650px;
    li {
      display: inline-block;
      font-size: 13px;
      margin-right: 25px;

      a,
      a:link,
      a:visited {
        color: $color_black;
      }
      
      a {
        @include arrow-left(4px);
      }

      a:hover {
        color: $link_hover;
      }
    }

    .footer-logo {
      margin-top: 20px;
    }
  }
  
  .footer-right {
    text-align: right;
    width: 390px;
    
    .btn {
      font-size: 16px;
      transform-origin: right top;

      span {
        display: inline-block;
        @include arrow-left(5px, #fff);
      }
    }

    small {
      color: $color_gray;
      display: block;
      font-size: 10px;
      margin-top: 71px;
    }
  }

  &.type02 {
    flex-wrap: wrap;
    padding-top: 73px;
    .footer-nav {
      width: 100%;
      margin-bottom: 23px;
      li {
        display: inline-block;
        font-size: 13px;
        margin-right: 25px;
  
        a,
        a:link,
        a:visited {
          color: $color_black;
        }
        
        a {
          @include arrow-left(4px);
        }
  
        a:hover {
          color: $link_hover;
        }
      }
    }
    .footer-left {
      width: 50%;  
      .footer-logo {
        margin-top: 0px;
      }
    }
    .footer-right {
      text-align: right;
      width: 50%;

      small {
        margin-top: 0px;
      }
    }
  }
}


// =========================================
//   pagetop
// =========================================
#pagetop {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  z-index: 9997;

  a {
    background-color: #fff;
    border: 1px solid #777;
    display: block;
    font-size: 0;
    height: 50px;
    text-indent: -9999px;
    overflow: hidden;
    
    &:hover {
      opacity: $hover_opacity;
      transition: color $hover_animate;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 8px auto 0;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      border: 10px solid transparent;
      border-bottom: 11px solid #777;
    }
  }
}


// =========================================
//   breadcrumbs
// =========================================
.breadcrumbs {
  margin-top: 20px;
  
  li {
    display: inline-block;
    padding-right: 1em;
    position: relative;
    
    &::after {
      content: "\3E";
      position: absolute;
      top: 50%;
      right: 0;
      display: inline;
      margin-top: -0.5em;
      line-height: 1;
    }
    
    &:last-child {
      padding-right: 0;
      &::after {
        content: none;
      }
    }
    
    a {
      color: $color_black;
      &:hover {
        color: $link_hover;
      }
    }
  }
}


// =========================================
//   main
// =========================================
#main {
  min-width: 1044px;
  position: relative;
}


// =========================================
//   SP
// =========================================
@include max-screen {

  .js-hidden,
  .js-hidden body {
    overflow: hidden;
    width: 100%;
    position: fixed;
  }

  // =========================================
  //   header
  // =========================================
  .headerFixed {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
  }

  #header {
    background-color: #fff;
    font-family: $font-family-go;
    position: relative;
    z-index: 9999;
    padding: 20px 0;
    min-width: 0;
    
    .sp {
      .header-inner {
        display: block;
        margin: 0;
        width: 100%;
      }
      
      .logo {
        width: 204px;
        margin-left: 15px;
      }
      
      .nav-btn-triger {
        position: absolute;
        top: 15px;
        right: $sp_contents_padding;
        z-index: 9998;
        
        button,
        button span {
          display: block;
          transition: all 0.4s;
        }

        button {
          background: none;
          border: 0 none;
          outline: none;
          padding: 0;
          position: relative;
          width: 40px;
          height: 40px;
          
          & span,
          &::after,
          &::before {
            content: "";
            display: block;
            position: absolute;
            left: 7px;
            width: 25px;
            height: 2px;
            background-color: $color_black;
            transition-duration: 0.2s;
          }

          &::before {
            top: 10px;
          }

          & span {
            top: 50%;
            transform: translateY(-50%);
            text-indent: -9999px;
            overflow: hidden;
          }

          &::after {
            bottom: 10px;
          }
        }

        &.active {
          button {
            span {
              opacity: 0;
            }

            &::before {
              transform: rotate(-45deg);
              transform-origin: right bottom;
            }
            &::after {
              transform: rotate(45deg);
              transform-origin: right top;
            }
          }
        }
      }

      .header-nav {
        display: none;
      }
    }
  }

  body.js_hidden {
    overflow: hidden;
  }


  // =========================================
  //   footer
  // =========================================
  #footer {
    font-family: $font-family-go;
    display: block;
    padding: 0 0 5px;
    
    .footer-left {
      li {
        display: block;
        font-size: 13px;
        margin: 0;

        a,
        a:link,
        a:visited {
          color: $color_black;
        }
        
        a {
          display: block;
          padding: 12px $sp_contents_padding 12px 6.6%;
          border-bottom: 1px dotted #cfcfcf;
          
          &::before {
            left: $sp_contents_padding;
          }
        }

        a:hover {
          color: $color_black;
        }
      }
    }
    
    .footer-right {
      margin-top: 30px;
      text-align: center;
      width: auto;
      
      .btn {
        font-size: 14px;
        padding: 5px 50px;
      }
      
      .footer-logo {
        margin: 30px auto 0;
        @include width-sp(560);
      }

      small {
        color: $color_gray;
        display: block;
        font-size: 10px;
        margin-top: 15px;
      }
    }

    &.type02 {
      
      padding-top: 0;
      .footer-nav {
        width: 650px;
        margin-bottom: 23px;
        li {
          display: block;
          font-size: 13px;
          margin: 0;
  
          a,
          a:link,
          a:visited {
            color: $color_black;
          }
          
          a {
            display: block;
            padding: 12px $sp_contents_padding 12px 6.6%;
            border-bottom: 1px dotted #cfcfcf;
            
            &::before {
              left: $sp_contents_padding;
            }
          }
  
          a:hover {
            color: $color_black;
          }
        }
      }
      .footer-left {
        width: 100%;  
      }
      .footer-right {
        margin-top: 30px;
        text-align: center;
        width: auto;
        
        .btn {
          font-size: 14px;
          padding: 5px 50px;
        }
        
        .footer-logo {
          margin: 30px auto 0;
          @include width-sp(560);
        }
  
        small {
          color: $color_gray;
          display: block;
          font-size: 10px;
          margin-top: 15px;
        }
      }
    }
  }
  
  
  // =========================================
  //   pagetop
  // =========================================
  #pagetop {
    font-size: 12px;
    display: block;
    position: static;
    bottom: auto;
    right: auto;
    width: auto;
    z-index: 0;

    a {
      background-color: #222;
      border: 0 none;
      color: #fff;
      display: block;
      font-family: $font-family-go;
      font-size: 14px;
      height: auto;
      text-indent: 0;
      text-align: center;
      overflow: hidden;
      padding: 10px 0;
      
      &:hover {
        opacity: 1;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 8px auto 0;
        vertical-align: middle;
        width: 10px;
        height: 10px;
        border: 10px solid transparent;
        border-bottom: 11px solid #777;
      }
      
      span {
        @include arrow-left-top(4px, #fff);
      }
    }
  }
  
  
  // =========================================
  //   gnav
  // =========================================
  #gnav {
    $iconBtnWidh: 13%;// アコーディオン「+」と 矢印「>」 のクリッカブル範囲横幅
    $linkHeight: 62px;// スマホグロナビリスト内のアイテム一行あたりの高さ


    display: block;
    background-color: transparent;
    position: absolute;
    top: 65px;// ヘッダーより下にスライド出現
    left: 0;
    width: 100%;
    min-width: 0;
    z-index: 10000;
    transform: translateX(100%);
    font-family: $font-family-go;

    .gnav-inner {
      background-color: #fff;
      width: 100%;
      height: 100vh;

      .inner {
        overflow-Y: auto;
        -webkit-overflow-scrolling: touch;
        height: 100%;
        padding-bottom: 160px;// アイフォンデフォルトのボトムメニューによるコンテンツが見切れてしまうことへの対策。最後の要素の下に余白を設ける。

        .singleMenu {
          position: relative;
          a {
            display: block;

            &,
            &:link,
            &:visited {
              color: #333;
            }
          }

          height: $linkHeight;
          padding-left: 15px;
          background-color: white;
          border-bottom: 1px solid #E0E0E0;
          font-weight: bold;
          @include alignCenter();

          .arrow { // 赤　矢印
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 2px;
            margin: auto;
            width: $iconBtnWidh;
          }
          
          .arrow::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            border-top: solid 2px $color_red;
            border-right: solid 2px $color_red;
            content: '';
          }

          .arrow.arrow-right::before { // 赤 >　矢印を右向きに
            transform: rotate(45deg);
          }
        }

        .megamenu {
          position: relative;

          a {
            display: block;

            &,
            &:link,
            &:visited {
              color: #333;
            }
          }

          .spMainLink {
            height: $linkHeight;
            padding-left: 15px;
            background-color: white;
            border-bottom: 1px solid #E0E0E0;
            font-weight: bold;
            @include alignCenter();
          }

          .accBtn_sp {// オープン・クローズボタン
            position: absolute;
            top: 0;
            right: 0;
            width: $iconBtnWidh;
            height: $linkHeight;

            &::before , &::after {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              width: 12px;
              height: 2px;
              background-color: $color_red;
              content: '';
              transition-duration: 0.3s;
            }

            &::after {
              transform: rotate(90deg);
            }

            &.on {
              &::after {
                transform: rotate(0deg);
              }
            }
          }
        
          .megamenu-wrap {
            width: 100%;
            display: none;
        
            li{
              a {
                display: block;
                @include arrow-left(6px);
                padding-left: 21px;

                &::before {
                  left: 15px;
                }
              }

              .spSubLink {
                background-color: #F9F9F9;
                border-bottom: 1px solid #E0E0E0;
                font-size: 12px;
                height: $linkHeight;
                padding-left: 32px;
                @include alignCenter();

                .spSubLinkSpan {
                  .spBold {
                    font-weight: bold;
                    display: block;
                    font-size: 12px;
                  }
                }
              }
            }
        
            ul ul {
              margin-left: 20px;
            }
          }

          .lineup {display: none;}

          figure {display: none;}
        }

        .spMainLinkWide {
          position: relative;
          display: block;
          width: 100%;
          height: $linkHeight;
          padding-left: 15px;
          color: #333;
          font-weight: bold;
          background-color: white;
          border-bottom: 1px solid #E0E0E0;
          @include alignCenter();

          .arrow {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 2px;
            margin: auto;
            width: $iconBtnWidh;
          }
          
          .arrow::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            border-top: solid 2px $color_red;
            border-right: solid 2px $color_red;
            content: '';
          }

          .arrow.arrow-right::before {
            transform: rotate(45deg);
          }
        }
      }
    }
    
    .gnav-serch {
      padding: 10px 25px 10px 15px;
      background-color: #F9F9F9;
      border-bottom: 1px solid #EFEFEF;
      dl {
        display: flex;
        align-items: center;
      }

      dt {
        padding-right: 10px;
        img {
          margin-top: 4px;
          width: 18px;
        }
      }
      
      dd {
        display: flex;
        flex: 1;
        overflow: hidden;
      }

      input[type="text"] {
        border: 1px solid #b5b4b4;
        border-radius: 5px 0 0 5px;
        display: block;
        flex: 1;
        font-size: 16px;
        padding: 10px;
        width: 100%;
      }
      
      .btn-submit {
        background-color: #b5b4b4;
        border: 1px solid #b5b4b4;
        border-radius: 0 5px 5px 0;
        display: block;
        padding: 0;
        text-indent: -9999px;
        overflow: hidden;
        width: 35px;
        @include arrow-left(7px, #fff);
        
        &::before {
          top: 0;
          bottom: 0;
          left: 5px;
          right: 0;
          margin: auto; 
        }
      }
    }

    .spHomeGallery {
      padding: 15px;
      background-color: white;

      a {
        display: flex;
        align-items: center;
        position: relative;
        background-color: #006E3D;
        border-radius: 5px;
        padding-left: 15px;
        font-size: 14px;
        font-weight: bold;
        color: white;
        width: 100%;
        height: 66px;

        .linkIcon {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 15px;
          margin: auto 0;
          width: 12px;
        }
      }
    }
  }


  // =========================================
  //   overlay
  // =========================================
  .overlay {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }
  

  // =========================================
  //   breadcrumbs
  // =========================================
  .breadcrumbs {
    margin-top: 15px;
    
    li {
      display: inline-block;
      padding-right: 1em;
      position: relative;
      
      &::after {
        content: "\3E";
        position: absolute;
        top: 50%;
        right: 0;
        display: inline;
        margin-top: -0.5em;
        line-height: 1;
      }
      
      &:last-child {
        padding-right: 0;
        &::after {
          content: none;
        }
      }
      
      a {
        color: $color_black;
        &:hover {
          color: $link_hover;
        }
      }
    }
  }
  

  // =========================================
  //   main
  // =========================================
  #main {
    min-width: 0;
    position: relative;

    &.paddingB-sp {
      padding-bottom: 40px;
    }
  }
}