// -----色-----
$color_black: #120603; // 黒
$color_gray:  #8a8a8a; // グレー
$color_blue:  #2d5d99; // 青
$color_red:   #e60012; // 赤

$link_hover:  #c57800; // リンクhover時

// -----余白-----
$sp_contents_padding: 2.5%; // SP版の画面左右の余白

// -----hover時-----
$hover_opacity: 0.7;
$hover_animate: 0.3s ease;

// -----font-family-----
$font-family-min: "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", "游明朝", YuMincho, serif;
$font-family-go: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Noto Sans Japanese", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;

